import React, { Component } from 'react';
import { Form, Breadcrumb, Button, Image } from 'react-bootstrap';
import 'moment/locale/id.js';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import remSep from "string-remove-thousand-separators";
import CurrencyFormat from 'react-currency-format';

class Pendaftaran extends Component {

  constructor(props) {
    super(props);

    this.state = {
      student_id: '',
      uniform_id: '',
      size: '',
      price: '',
      qty: '',
      name: '',
      image_2: '',
      image_1: '',
      uniform1: '',
      
    }
  }

  componentDidMount() {


    this.setState({ student_id: localStorage.getItem('DATA_JALUR').split('-')[0],  uniform_data: JSON.parse(localStorage.getItem('uniform1')) });
    console.log(localStorage.getItem('DATA_JALUR').split('-')[0])
    fetch(global.variable.LINK_API + 'student/detail/' + localStorage.getItem('DATA_JALUR').split('-')[0],
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data), 
      })

      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState(resData.data[0]);
          //this.setState({note_verification : resData.data[0].note_verification});
          this.setState({ is_show: true });
          // console.log(this.state);
          console.log(resData.data[0].id)
        }
        if (this.state.upload_upp) {
          let totalnominal = 0;
          for (let i = 0; i < this.state.upload_upp.length; i++) {
            totalnominal += this.state.upload_upp[i].nominal;
          }
          this.setState({ total: totalnominal })
        }

        let potongan2 = this.state.data_bayar[0].nominal * this.state.data_bayar[0].potongan / 100

        let nominal2 = this.state.data_bayar[0].nominal - potongan2;
        this.setState({ hasil: nominal2 });

        let sisanominal = this.state.hasil - this.state.total;
        this.setState({ sisa: sisanominal });

        let persentase = (this.state.total / this.state.hasil * 100).toFixed(2);
        this.setState({ persen: persentase })
        console.log(this.state.persen)

      })


    fetch(global.variable.LINK_API + 'uniform/' + localStorage.getItem('DATA_JALUR').split('-')[0],
      {

        method: 'get',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          // for(let i=0; i<resData.data.length; i++) {
          //   this.setState({image_1 : resData.data[i].link_product})
          //   console.log("test :" + this.state.image_1);
          // }
          // for(let i=0; i<resData.data.length; i++) {
          //   this.setState({image_2 : resData.data[i].link_description})
          // }

          //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

          // this.setState({image_1 : resData.data[0].link_product})
          // this.setState({image_2 : resData.data[0].link_description})
          //localStorage.setItem('prm_uniform', JSON.stringify(resData.data));
     
          this.setState({ uniform: resData.data, is_showdata : true,});


          console.log("res data");
          console.log(resData.data);
          console.log("-----");

          
        }

        //console.log(resData);
        console.log(this.state.uniform_data);
        console.log(JSON.parse(localStorage.getItem('prm_uniform')));
        this.setState({ uniform1: localStorage.getItem('uniform1') });
        console.log(this.state.uniform_data.length)



      })

     

  }

  // onChangeTotal =  e => {let jumlahtotal = this.state.qty * this.state.size.prize
  //                         this.setState({totalharga:jumlahtotal})
  //                       console.log(this.state.size)
  //                       console.log(this.state.totalharga)}


  onChangeNom = e => this.setState({ [e.target.name]: remSep(e.target.value) })




  // onChange2 () {

  //   if(this.state.uniform_id === '12'){
  //     <div className='rowHorizontal'>
  //     <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama </Form.Label>
  //     <Form.Label className="col-md-6" style={{margin:5,}}>{uni[i].name} </Form.Label>
  //   </div>
  //   }else{''}
  // }
  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
    alert(this.state.uniform_id)

  }

  createSelectUniform() {
    let items = [];
    let params = this.state.uniform;

    console.log(this.state.uniform)
    for (let i = 0; i < params.length; i++) {
      items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);

    }
    return items;
  }

  createSelectUniform() {
    let items = [];
    let params = this.state.uniform;

    console.log(this.state.uniform)
    for (let i = 0; i < params.length; i++) {
      items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);

    }
    return items;
  }

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });

    console.log("data" + [e.target.value]);
    const arrayi = 0;
    // for(let i=0; i<this.state.uniform.length; i++){
    //   if(this.state.uniform['id']===e.target.value){
    //       arrayi = i;
    //   }
    // }

    let itemSelected = null;
    this.state.uniform.map((item, idx) => {

      console.log("data DB :: " + item.id + ' === ' + e.target.value)
      if (item.id == e.target.value) {
        itemSelected = item
        console.log('masuk if statement')
        console.log(item)

      }
    })


    // const arrayselected = this.state.uniform[e.target.value - 1];
    this.setState({ image_1: itemSelected.link_product })
    this.setState({ image_2: itemSelected.link_description })
    // console.log(arrayselected)
    this.setState({ uniform_id: e.target.value })

    fetch(global.variable.LINK_API + 'uniform/detail/' + itemSelected.id,
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState({ size: resData.data });
        }
        console.log(this.state.size);
        // console.log(this.state.uniform.id);


      })

    // const arrayselected2 = this.state.size[e.target.value-1];
    // this.setState({size2: arrayselected2.id})
    // console.log(arrayselected2)

  }

  onDropdown2Selected(e) {
    this.setState({ [e.target.name]: e.target.value });

    console.log("data : " + [e.target.value]);
    const price = e.target.value.split('-')[1];
    const size = e.target.value.split('-')[0];
    console.log(price)
    this.setState({ size_uni: size });
    this.setState({ price_uni: price })
  }

  createSelectSize() {
    let items = [];
    let params = this.state.size;

    console.log(params)
    for (let i = 0; i < params.length; i++) {
      items.push(<option key={i} value={params[i].id_size}>{params[i].size}-{params[i].price}</option>);

    }

    return items;

  }



  validateForm() {
    return this.state.name.length > 0
      && this.state.nickname.length > 0
      && this.state.uniform_id.length > 0
      && this.state.gender_id.length > 0
      && this.state.nis.length > 0
      && this.state.nisn.length > 0
      && this.state.pob.length > 0
      && this.state.dob.length > 0
      && this.state.birth_certificate.length > 0;
  }

  runswal(){
    var span = document.createElement("span");
    //span.innerHTML = "Terima kasih anda telah mendaftar ke SMP PL Domenico Savio Semarang. \n\nNomor Pendaftaran anda adalah " + resData.data.year + '-' + resData.data.registration_type_id  + '-' +  resData.data.registration_number +"\n\n<strong>Silahkan melakukan transfer biaya pendaftaran sebesar Rp.100.000 ke rekening Bank Mandiri no 136-00-0149000-9 atas nama SMP PANGUDI LUHUR DOMENICO SAVIO SMG dengan mencantumkan nomor pendaftaran anda pada kolom catatan.</strong> \n\nData anda akan kami proses dalam waktu maksimal 24 jam setelah anda mengunggah bukti transfer.";
    span.innerHTML = this.state.info_swal;

    global.swal({title : "Success", /*"Kode Pembayaran anda adalah " + resData.data.year + resData.data.registration_type_id + resData.data.registration_number + "\n Nomor Rekening : 136-00-0149000-9 \na/n SMP Pangudi Luhur Domenico Savio SMG"*/
    icon: "success",
    
    content: span,})
    .then((value) => {
      localStorage.removeItem('uniform1');
      window.location.href="/pemesananseragam";
    });
  }
  
  handleSubmit = () => {
    let dataArray = [];

    const data = { student_id: this.state.student_id, data: localStorage.getItem('uniform1') }
    console.log(data)
    if(localStorage.getItem('uniform_name')){
    dataArray = JSON.parse(localStorage.getItem('uniform1'))
    // console.log(this.state.uniform_id === '12')

    let ok = 0;
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].uniform_id === '12') {
        ok = 1;
        console.log(dataArray[i].uniform_id)
      }
    }
    if (!ok) {
      global.swal("Error", 'Silahkan isi Bordir Nama', "error")
    } else {

      return fetch(global.variable.LINK_API + 'uniform/insert',
        {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          body: JSON.stringify(data),
        })

        .then(response => response.json())
        .then(resData => {
          console.log(resData);


          if (resData.message === "Success") {

            global.swal("Success", 'Berhasil Submit', "success")
            var text = "Berhasil Submit, <strong>Silahkan melakukan transfer ke rekening <span style='color:red'>Bank Mandiri </br>no 136-00-0149000-9 atas nama SMP PANGUDI LUHUR DOMENICO SAVIO SMG</span> dengan mencantumkan nomor pendaftaran anda pada kolom catatan.</strong>";
              this.setState({info_swal : text})
            this.runswal();
          }
        })
    }
  }
  else{ global.swal("Error", 'Nama pada Bordir Nama kosong', "error")}

  }

  handleNext = event => {
    event.preventDefault();    
    
    window.location.href="/pemesananseragampreview";

  }

  handleAdd = () => {
    // console.log("state 4 " + JSON.stringify(this.state));
    let item = [];

    if (localStorage.getItem('uniform1')) {
      item = JSON.parse(localStorage.getItem('uniform1'));
      
    }
    if (this.state.uniform_id === '12') {
      item.push({ student_id: this.state.student_id, uniform_id: this.state.uniform_id, size: this.state.size_uni, price: this.state.price_uni, qty: this.state.qty, name: this.state.name });
    } else { item.push({ student_id: this.state.student_id, uniform_id: this.state.uniform_id, size: this.state.size_uni, price: this.state.price_uni, qty: this.state.qty }); }

    console.log("score " + JSON.stringify(item));

    localStorage.setItem('uniform1', JSON.stringify(item));

    this.updateParamUniform();



    // window.location.href = "/pemesananseragamdetail";


  }

  updateParamUniform() {

    console.log('fn')
    let item = [];
    let dataUniform = this.state.uniform
    if (localStorage.getItem('uniform1')) {
      item = JSON.parse(localStorage.getItem('uniform1'));

    
      console.log("check local uniform1 is exist")
      console.log(dataUniform.length)

      for (let i = 0; i < dataUniform.length; i++) {
        // console.log('data uniform')
        for (let x = 0; x < item.length; x++) {
          // console.log('data local uniform')

          // console.log("check data : "+dataUniform[i]['id'] +"=="+ item[x]['uniform_id'])
          if(dataUniform[i]['id'] == item[x]['uniform_id']){

            dataUniform.splice(i,1);
          }
        }
      }
     
      // console.log(dataUniform);
      this.setState({uniform:dataUniform})
      // this.setState({prm_uniform:dataUniform})

    } else{
      console.log("check local uniform1 is not exist")
    }

  }

  

  showUniform() {
    if (localStorage.getItem('uniform1')) {
      let uni = JSON.parse(localStorage.getItem('uniform1'));
      let prm_uniform = JSON.parse(localStorage.getItem('prm_uniform'));
      let showitem = [];
      let totalnominal = 0;
     
   
      for (let i = 0; i < uni.length; i++) {
        totalnominal += uni[i].qty * uni[i].price

        let arrayselected=null
        for (let z = 0; z < prm_uniform.length; z++) {
          console.log(prm_uniform[z].id +"=="+ uni[i].uniform_id)
          if (prm_uniform[z].id  == uni[i].uniform_id) {
            
                 arrayselected = prm_uniform[z]
            
              }
        }
       
        

        // arrayselected=null;
         arrayselected = this.state.uniform[uni[i].uniform_id-1];
        
        showitem.push(

          <div key={uni[i].student_id}>
            <hr />
            <div>
             
              <div className='rowHorizontal'>
                <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Seragam </Form.Label>
                <Form.Label className="col-md-6" style={{ margin: 5, }}>{arrayselected ? arrayselected.name :""} </Form.Label>

              </div>
              <div className='rowHorizontal'>
                <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Ukuran </Form.Label>
                <Form.Label className="col-md-6" style={{ margin: 5, }}>{uni[i].size} </Form.Label>
              </div>
              <div className='rowHorizontal'>
                <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Harga </Form.Label>
                <Form>
                  <CurrencyFormat
                    input="number"
                    name="totalharga"
                    onChange={this.onChangeNom}
                    value={uni[i].price}
                    maxLength="25" thousandSeparator={true}
                    prefix={'Rp.'}
                    style={{ height: 30, width: '100%' }} />
                </Form>
              </div>
              <div className='rowHorizontal'>
                <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Jumlah </Form.Label>
                <Form.Label className="col-md-6" style={{ margin: 5, }}>{uni[i].qty} </Form.Label>
              </div>
              <div className='rowHorizontal'>
                <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Total Harga </Form.Label>
                <Form>
                  <CurrencyFormat
                    input="number"
                    name="totalharga"
                    onChange={this.onChangeNom}
                    value={uni[i].qty * uni[i].price}
                    prefix={'Rp.'}
                    maxLength="25" thousandSeparator={true}
                    style={{ height: 30, width: '100%' }} />
                </Form>
              </div>
            </div>

            {arrayselected.id == '12' ?
              <div className='rowHorizontal'>
                <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Nama </Form.Label>
                <Form.Label className="col-md-6" style={{ margin: 5, }}>{uni[i].name} </Form.Label>
              </div>
              : ''}


          </div>



        );
      }

      if (totalnominal > 0) {
        showitem.push(
          <div>
            <hr></hr>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Total Harga Keseluruhan </Form.Label>
              <Form>
                <CurrencyFormat
                  input="number"
                  name="totalharga"
                  onChange={this.onChangeNom}
                  value={totalnominal}
                  prefix={'Rp.'}
                  maxLength="25" thousandSeparator={true}
                  style={{ height: 30, width: '100%' }} />
              </Form>
            </div>
          </div>
        );
      }
      return showitem;

    }

    

  }

  


  handleClear = (index) => {

    let rmv = []
    rmv = JSON.parse(localStorage.getItem('uniform1'));
    rmv.splice(index, 1);// dibelakang koma untuk brapa jumlah yg diapus
    console.log(rmv)

    localStorage.setItem('uniform1', JSON.stringify(rmv));
    window.location.href = "/pemesananseragamdetail";
  }


  // handleClear = () => {
  //   localStorage.removeItem('uniform1');
  //   window.location.href="/pemesananseragamdetail";

  // }

  // showRemove(){

  //   if(localStorage.getItem('uniform1')){
  //     return(
  //       <Button onClick={this.handleClear} block style={{margin:5, height:30, width: 100}}>Clear Data</Button>
  //     );
  //   }

  // }


  handleBack = () => {
    window.location.href = "/pemesananseragamdetail";
  }


  Total(){
    let uniform_array = JSON.parse(localStorage.getItem('uniform1'));
    let totalnominal = 0;
    for (let i = 0; i < uniform_array.length; i++) {
        totalnominal +=  uniform_array[i].qty * uniform_array[i].price
    }
    console.log(totalnominal)
    return(totalnominal)
  }
  render() {
    // console.log(this.state.uniform1);
let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  {this.state.persen >= 50 ?

                    <div>
                      <Breadcrumb>
                        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Pemesanan Searagam Detail</Breadcrumb.Item>
                      </Breadcrumb>

                      <Form onSubmit={this.handleAdd}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Nama Siswa</Form.Label>
                          <Form.Control
                            type="Text"
                            name="jalur"
                            value={localStorage.getItem('DATA_JALUR')}
                            disabled />
                        </Form.Group>
                        
                      

                        <div className="rowHorizontal" >
              <div style={{margin:5, marginLeft:100, fontSize:11}}>  
              <table border="1">
                <thead>
                      <tr >
                        <th width="50" style={{textAlign: "center", fontSize:12}}>No. </th>
                        <th width="150" style={{textAlign: "center", fontSize:12}}>Nama Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Harga Satuan</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Ukuran</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.is_showdata?
                       
                       
                        this.state.uniform.map((item, idx) => {
                        let showitem = [];
                        let price = 0;
                        let qty = 0;
                        let size ='-';
                        let total = 0;
                        
                        counter++;
                       
                        let uniform_array = JSON.parse(localStorage.getItem('uniform1'));
                        console.log(uniform_array.length)
                        for (let i = 0; i < uniform_array.length; i++) {
                           
                        // this.state.uniform_data.map((item2, idx2) => {
                            
                              console.log(uniform_array[i].uniform_id + "=="  +item.id)
                            if(uniform_array[i].uniform_id == item.id){
                                price = uniform_array[i].price;
                                qty = uniform_array[i].qty;
                                size = uniform_array[i].size;
                                total = uniform_array[i].qty*uniform_array[i].price;

                               console.log("masuk if")
                            }
                            else{
                                 console.log("tidak masuk if")

                            }
                           

                           
                        }
                        return (
                            <tr key={item.id}>
                                <td style={{textAlign: "center"}}>{counter}</td>
                                <td>{item.name}</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={price}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                                <td style={{textAlign: "center"}}>{qty}</td>
                                <td style={{textAlign: "center"}}>{size}</td>  
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={total}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                            </tr>
                            
                        )
                      
                       
                        
                        
                        })
                    :''}
                      </tbody>
                     
                            <tr>
                                
                                <td colSpan="5" style={{textAlign: "center", fontSize:12}}>Total</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.Total()}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                    </td>
                            </tr>
                      
                   
                    </table>
                  </div>
            </div>
                        {/* {this.showRemove()} */}
                        {/* {this.showUniform()} */}
                        {/* {this.state.uniform1?
                        this.showTotal() :''
                        } */}

                <div className='rowHorizontal'>
                <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>Nama </Form.Label>
                <Form.Label className="col-md-6" style={{ margin: 5, }}>{localStorage.getItem('uniform_name')?localStorage.getItem('uniform_name').toUpperCase():''} </Form.Label>
                </div>
                <div className='rowHorizontal'>
                
                <Form.Label style={{ fontSize:18, color:'red'}}>*Pastikan pemesanan seragam sudah sesuai sebelum Anda melakukan Submit karena setelah Submit tidak akan bisa di revisi </Form.Label>
                </div>
                        <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{ margin: 5, height: 50 }} >Back</Button> 
                          <Button onClick={this.handleSubmit} block style={{ margin: 5, height: 50 }} >Submit</Button>
                        </div>
                      </Form>
                    </div>
                    : 'UPP Belum Mencapai 50%'}

                </div>

              </div>
            </div>
          </div>
        </section>
      </div>

    );
  }
}

export default Pendaftaran;