import React, { Component } from 'react';
import { Breadcrumb, Table, Button} from 'react-bootstrap';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require('moment');

class Home extends Component {

  constructor(props){
    super(props);

    this.state={
        data_student : '',
        is_show: false,
        data: '',
    }
  }


  handleClick = param => e => {
    e.preventDefault();
    localStorage.setItem('student_id', param)
    window.open("/detailstudent", '_blank');
  }

  componentDidMount(){

    
    console.log((localStorage.getItem('data_report')))
    let data_report = JSON.parse(localStorage.getItem('data_report'));

    let data = []
    data_report.map((item) =>{
      let items =  Object.create({});
      items.pendaftaran =item.year + '-' + item.registration_type_id  + '-' +  item.registration_number;
      items.jalur = item.registration_type.name;
      items.fullname = item.fullname;
      items.nickname = item.nickname;
      items.gender = item.gender.name;
      items.nik = item.nik;
      items.nis = item.nis;
      items.nisn = item.nisn;
      items.tempatlahir = item.pob;
      items.tanggallahir = moment(item.dob, 'YYYY-MM-DD').format('DD-MM-YYYY')
      items.akta = item.birth_certificate;
      items.agama = item.religion.name;
      items.kewarganegaraan = item.citizen.name;
      items.alamat = item.address;
      items.rt = item.rt;
      items.rw = item.rw;
      items.kelurahan = item.sub_district;
      items.kecamatan = item.district;
      items.kabupaten = item.sub_province;
      items.provinsi = item.province;
      // items.kodepos = item.zipcode;
      // items.bujur = item.longitude;
      // items.lintang = item.latitude;
      items.tinggal = item.stay.name;
      items.transportasi = item.transportation.name;
      items.jarak = item.school_distance_hour;
      items.waktu = item.school_distance_minute;
      items.hp = item.hp;
      items.email = item.email;
      items.anak = item.child;
      items.saudara = item.total_children;
      // items.darah = item.blood_type.name;
      // items.tinggi = item.height;
      // items.berat = item.weight;
      // items.kepala = item.head_length;
      items.sekolahasal = item.school_from.name;
      items.namasekolah = item.school_from_name;
      items.alamatsekolah = item.school_from_address;
      items.spp = item.spp;
      items.status = item.flow.name;
      items.catatan = item.note_reject;
      items.no_kk = item.no_kk;
      items.sibling_dependent = item.sibling_dependent;
      items.bill_pln = item.bill_pln;
      items.electrical_power_id = item.electrical_power?item.electrical_power.name:'';
      items.study_year = item.study_year;
      items.parent_status_id = item.parent_status?item.parent_status.name:'';
      items.both_parent_work = item.both_parent_work?item.both_parent_work.name:'';
      items.scheduleinterview = item.schedule.length?moment(item.schedule[0].schedule_interview_at, 'YYYY-MM-DD, hh:mm').format('DD-MM-YYYY, HH:mm'):'';
      items.schedulesocialization = item.schedule.length?moment(item.schedule[0].schedule_socialization_at	, 'YYYY-MM-DD, hh:mm').format('DD-MM-YYYY, HH:mm'):'';
      items.is_offline = item.is_offline;

      if(item.guardians[0]){
        items.guardian_name0 = item.guardians[0].guardian_name;
        items.fullname0 = item.guardians[0].fullname;
        items.nik0 = item.guardians[0].nik;
        items.lahirayah = moment(item.guardians[0].dob, 'YYYY-MM-DD').format('DD-MM-YYYY');
        items.agama0 = item.guardians[0].religion_name;
        items.status0 = item.guardians[0].status_guardian_name;
        items.pendidikan0 = item.guardians[0].education_name;
        items.pekerjaan0 = item.guardians[0].occupation_name;
        items.penghasilan0 = item.guardians[0].salary;
        items.hp0 = item.guardians[0].hp;
        items.alamat0 = item.guardians[0].address;
      }else{
        items.guardian_name0='Ayah';
        items.fullname0='';
        items.nik0 = '';
        items.lahirayah =  '';
        items.agama0 = '';
        items.status0 = '';
        items.pendidikan0 = '';
        items.pekerjaan0 = '';
        items.penghasilan0 = '';
        items.hp0 = '';
        items.alamat0 = '';
      }
      if(item.guardians[1]){
      items.guardian_name1 = item.guardians[1].guardian_name;
      items.fullname1 = item.guardians[1].fullname;
      items.nik1 = item.guardians[1].nik;
      items.lahiribu = moment(item.guardians[1].dob, 'YYYY-MM-DD').format('DD-MM-YYYY');
      items.agama1 = item.guardians[1].religion_name;
      items.status1 = item.guardians[1].status_guardian_name;
      items.pendidikan1 = item.guardians[1].education_name;
      items.pekerjaan1 =item.guardians[1].occupation_name;
      items.penghasilan1 = item.guardians[1].salary;
      items.hp1 = item.guardians[1].hp;
      items.alamat1 = item.guardians[1].address;
      }else{
        items.guardian_name1='Ibu';
        items.fullname1='';
        items.nik1 = '';
        items.lahiribu =  '';
        items.agama1 = '';
        items.status1 = '';
        items.pendidikan1 = '';
        items.pekerjaan1 = '';
        items.penghasilan1 = '';
        items.hp1 = '';
        items.alamat1 = '';
      }

      if(item.guardians[2]){
        items.guardian_name2=item.guardians[2].guardian_name;
        items.fullname2=item.guardians[2].fullname;
        items.nik2 = item.guardians[2].nik;
        items.lahirwali =  moment(item.guardians[2].dob, 'YYYY-MM-DD').format('DD-MM-YYYY');
        items.agama2 = item.guardians[2].religion_name;
        items.status2 = item.guardians[2].status_guardian_name;
        items.pendidikan2 = item.guardians[0].education_name;
        items.pekerjaan2 = item.guardians[2].occupation_name;
        items.penghasilan2 = item.guardians[2].salary;
        items.hp2 = item.guardians[2].hp;
        items.alamat2 = item.guardians[2].address;

      }else{
        items.guardian_name2='Wali';
        items.fullname2='';
        items.nik2 = '';
        items.lahirwali =  '';
        items.agama2 = '';
        items.status2 = '';
        items.pendidikan2 = '';
        items.pekerjaan2 = '';
        items.penghasilan2 = '';
        items.hp2 = '';
        items.alamat2 = '';
      }

      items.note = item.note_verification?
                   item.note_verification:'';



      data.push(items);

    })
    this.setState({report:data})

  }


  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Report Pendaftaran</Breadcrumb.Item>
                          </Breadcrumb>

                          <ExcelFile element={<Button block style={{margin:5, height:50}}>Download Data</Button>}>
                            <ExcelSheet data={this.state.report} name="Report Pendaftaran">
                                <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran"/>
                                <ExcelColumn label="Jalur" value="jalur"/>
                                <ExcelColumn label="Nama Lengkap" value="fullname"/>
                                <ExcelColumn label="Nomor Panggilan" value="nickname"/>
                                <ExcelColumn label="Jenis Kelamin" value="gender"/>
                                <ExcelColumn label="NIK" value="nik"/>
                                <ExcelColumn label="No KK" value="no_kk"/>
                                <ExcelColumn label="NIS" value="nis"/>
                                <ExcelColumn label="NISN" value="nisn"/>
                                <ExcelColumn label="Tempat Lahir" value="tempatlahir"/>
                                <ExcelColumn label="Tanggal Lahir" value="tanggallahir"/>
                                <ExcelColumn label="No Akta Kelahiran" value="akta"/>
                                <ExcelColumn label="Agama" value="agama"/>
                                <ExcelColumn label="Kewarganegaraan" value="kewarganegaraan"/>
                                <ExcelColumn label="Alamat" value="alamat"/>
                                <ExcelColumn label="RT" value="rt"/>
                                <ExcelColumn label="RW" value="rw"/>
                                <ExcelColumn label="Kelurahan" value="kelurahan"/>
                                <ExcelColumn label="Kecamatan" value="kecamatan"/>
                                <ExcelColumn label="Kabupaten/Kota" value="kabupaten"/>
                                <ExcelColumn label="Provinsi" value="provinsi"/>
                                <ExcelColumn label="Kode Pos" value="kodepos"/>
                                {/* <ExcelColumn label="Bujur" value="bujur"/>
                                <ExcelColumn label="Lintang" value="lintang"/> */}
                                <ExcelColumn label="Tinggal Dengan" value="tinggal"/>
                                <ExcelColumn label="Moda Transportasi" value="transportasi"/>
                                <ExcelColumn label="Jarak Tempuh(km)" value="jarak"/>
                                <ExcelColumn label="Waktu Tempuh" value="waktu"/>
                                <ExcelColumn label="No. Handphone" value="hp"/>
                                <ExcelColumn label="Email" value="email"/>
                                <ExcelColumn label="Anak ke" value="anak"/>
                                <ExcelColumn label="Dari Bersaudara" value="saudara"/>
                                <ExcelColumn label="Saudara Yang Masih Menjadi Tanggungan Orang Tua" value="sibling_dependent"/>
                                {/* <ExcelColumn label="Golongan Darah" value="darah"/>
                                <ExcelColumn label="Tinggi (cm)" value="tinggi"/>
                                <ExcelColumn label="Berat (kg)" value="berat"/>
                                <ExcelColumn label="Lingkar Kepala (cm)" value="kepala"/> */}
                                <ExcelColumn label="Sekolah Asal" value="sekolahasal"/>
                                <ExcelColumn label="Nama Sekolah Asal" value="namasekolah"/>
                                <ExcelColumn label="Alamat Sekolah Asal" value="alamatsekolah"/>
                                <ExcelColumn label="Lama Belajar" value="study_year"/>
                                <ExcelColumn label="Uang SPP Sekolah Asal" value="spp"/>
                                <ExcelColumn label="Daya Listrik" value="electrical_power_id"/>
                                <ExcelColumn label="Status" value="status"/>
                                <ExcelColumn label="Catatan"value="catatan"/>

                                <ExcelColumn label="Keadaan Orang Tua" value="parent_status_id"/>
                                <ExcelColumn label="Keadaan Pekerjaan Orang Tua" value="both_parent_work"/>
                                <ExcelColumn label="Orang Tua" value="guardian_name0"/>
                                <ExcelColumn label="Nama Lengkap"value="fullname0"/>
                                <ExcelColumn label="NIK"value="nik0"/>
                                <ExcelColumn label="Tanggal Lahir"value="lahirayah"/>
                                <ExcelColumn label="Agama"value="agama0"/>
                                <ExcelColumn label="Status"value="status0"/>
                                <ExcelColumn label="Pendidikan"value="pendidikan0"/>
                                <ExcelColumn label="Pekerjaan"value="pekerjaan0"/>
                                <ExcelColumn label="Penghasilan"value="penghasilan0"/>
                                <ExcelColumn label="no. HP"value="hp0"/>
                                <ExcelColumn label="Alamat"value="alamat0"/>


                                <ExcelColumn label="Orang Tua" value="guardian_name1"/>
                                <ExcelColumn label="Nama Lengkap"value="fullname1"/>
                                <ExcelColumn label="NIK"value="nik1"/>
                                <ExcelColumn label="Tanggal Lahir"value="lahiribu"/>
                                <ExcelColumn label="Agama"value="agama1"/>
                                <ExcelColumn label="Status"value="status1"/>
                                <ExcelColumn label="Pendidikan"value="pendidikan1"/>
                                <ExcelColumn label="Pekerjaan"value="pekerjaan1"/>
                                <ExcelColumn label="Penghasilan"value="penghasilan1"/>
                                <ExcelColumn label="no. HP"value="hp1"/>
                                <ExcelColumn label="Alamat"value="alamat1"/>

                                <ExcelColumn label="Orang Tua" value="guardian_name2"/>
                                <ExcelColumn label="Nama Lengkap"value="fullname2"/>
                                <ExcelColumn label="NIK"value="nik2"/>
                                <ExcelColumn label="Tanggal Lahir"value="lahirwali"/>
                                <ExcelColumn label="Agama"value="agama2"/>
                                <ExcelColumn label="Status"value="status2"/>
                                <ExcelColumn label="Pendidikan"value="pendidikan2"/>
                                <ExcelColumn label="Pekerjaan"value="pekerjaan2"/>
                                <ExcelColumn label="Penghasilan"value="penghasilan2"/>
                                <ExcelColumn label="no. HP"value="hp2"/>
                                <ExcelColumn label="Alamat"value="alamat2"/>

                                <ExcelColumn label="Catatan Tentang Pendaftar"value="note"/>
                                <ExcelColumn label="Jadwal Sosialisasi"value="schedulesocialization"/>
                                <ExcelColumn label="Jadwal Wawancara"value="scheduleinterview"/>
                            </ExcelSheet>
                          </ExcelFile>
                                
                          <div className="box-body">
                            <div className="row">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Nama Lengkap (Nama dapat diklik)</th>
                                  <th>Nomor Pendaftaran</th>
                                  <th>Nomor Handphone</th>
                                  <th>Status</th>
                                  <th>Catatan</th>
                                  <th>Pendaftaran</th>
                                </tr>
                              </thead>
                              <tbody>
                              {data_report.map((item) =>{
                                  
                                  console.log(item)
                                  counter++;
                              return(
                                <tr>
                                  <td>{counter}</td>
                                  <td style={{color:'blue'}} onClick = {this.handleClick(item.id)}>{item.fullname}</td>
                                  <td>{item.year + '-' + item.registration_type_id  + '-' +  item.registration_number }</td>
                                  <td>{item.hp}</td>
                                  <td>{item.flow.name}</td>
                                  <td>{item.note_reject}</td>
                                  <td>{item.is_offline===1?
                                      <div style={{color:'red'}}>Offline</div>
                                      :
                                      <div style={{color:'green'}}>Online</div>  
                                      }
                                  </td>
                                </tr>
                              )
                             })}
                             </tbody>
                            </Table>
                            </div>
                          </div>
                                
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Home;