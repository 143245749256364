import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
const moment = require('moment');
class Home extends Component {

  constructor(props){
    super(props);

    this.state={
        data_student : '',
        is_show: false,
        id_selected: '',
        whatsapp: '',
    }
  }

  componentDidMount(){
    fetch(global.variable.LINK_API + 'student',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({data_student : resData.data});
        this.setState({is_show : true});
        //console.log(this.state);
      }
    })
  }

  handleReject= param => e => {
    console.log(param)

    const data = { student_id :  param, flow_id : 99, note_confirmation: ''}

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Tolak', "success")
        .then((value) => {
          window.location.href="/home";
        });
      }
       
    })
  }

  handleApprove= param => e => {
    console.log(param)

    const data = { student_id :  param, }

    fetch(global.variable.LINK_API + 'student/confirmation',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Disetujui', "success")
        .then((value) => {
          window.location.href="/home";
        });
      }
       
    })
  }

  handleOnline= param => e => {
    const data = { student_id :  this.state.id_selected, flow_id : 5, note_confirmation: '', interview_method: 2, whatsapp: this.state.whatsapp}

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        const moment = require('moment');
        let m = moment();  
        m = moment(resData.data.schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
        global.swal("Success", 'Jadwal Wawancara adalah ' + m , "success")
        .then((value) => {
          window.location.href="/home";
        });
      }
       
    })
  }

  handleTatapMuka= param => e => {
    const data = { student_id :  param, flow_id : 5, note_confirmation: '', interview_method: 1}

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);

        const moment = require('moment');
        let m = moment();  
        m = moment(resData.data.schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
        global.swal("Success", 'Jadwal Wawancara adalah ' + m , "success")
        .then((value) => {
          window.location.href="/home";
        });
      }
       
    })
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
  }

  isValidURL(string){
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  form(){
    
    let array = this.state.data_student;
    let showitem = [];
    let currency = Intl.NumberFormat('en-ID');

    for (let i = 0; i < array.length; i++) { 
      showitem.push(
        <div key={array[i].id}>
          <hr/>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Nama </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: {array[i].fullname} </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>No Pendaftaran </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { array[i].year + '-' + array[i].registration_type_id  + '-' +  array[i].registration_number } </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Status </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { array[i].flow.name}</Form.Label>
          </div>
          {array[i].flow_id===2?
          <>
          {/* <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>No VA Pendaftaran</Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { array[i].id+ '42101001'}</Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Nominal Transfer Pendaftaran </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: {'Rp. ' + currency.format(array[i].registration_type.registration_fee)}</Form.Label>
          </div> */}
          </>
          : ''}
          {array[i].flow_id===4?
          <>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Tanggal Test </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { moment(array[i].schedule[0].schedule_test_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss')}</Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Tempat/ Link Test </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { this.isValidURL(array[i].schedule[0].classroom_id)?<a href={array[i].schedule[0].classroom_id} target="_blank">{array[i].schedule[0].classroom_id}</a>:array[i].schedule[0].classroom_id}</Form.Label>
          </div>
          </>
          : ''}
          {array[i].flow_id===5?
          <>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Tanggal Wawancara </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { moment(array[i].schedule[0].schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss')}</Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Tempat/ Link Wawancara </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { this.isValidURL(array[i].schedule[0].schedule_interview_place)?<a href={array[i].schedule[0].schedule_interview_place} target="_blank">{array[i].schedule[0].schedule_interview_place}</a>:array[i].schedule[0].schedule_interview_place}</Form.Label>
          </div>
          </>
          : ''}
          {array[i].is_confirmation?
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 250, margin:5}}>Alasan Pindah</Form.Label>
            <Form.Label className="col-md-6" style={{ margin:5}}>: {array[i].note_change_registration_type}</Form.Label>
          </div>
          : ''}
          {array[i].is_confirmation?
          <div className='rowHorizontal'>
            <Form.Label className="col-md-8" style={{ margin:5}}>{array[i].note_confirmation}</Form.Label>
            <Button className="col-md-2" onClick={this.handleApprove(array[i].id)}  style={{margin:2, height:30}}>Setuju</Button>
            <Button className="col-md-2" onClick={this.handleReject(array[i].id)}  style={{margin:2, height:30}}>Tolak</Button>
          </div>
          : ''}
          {array[i].flow_id===10?
          <div className='rowHorizontal'>
            <Form.Label className="col-md-8" style={{ margin:5}}>Pilih metode wawancara yang diinginkan</Form.Label>
            <Button className="col-md-2" data-toggle="modal" data-target="#exampleModal" onClick={() => this.setState({id_selected:array[i].id})} style={{margin:2, height:30}}>Online</Button>
            <Button className="col-md-2" onClick={this.handleTatapMuka(array[i].id)}  style={{margin:2, height:30}}>Tatap Muka</Button>
          </div>
          : ''}
        </div>
      );
    }

    return showitem;
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          </Breadcrumb>
                                
                          <div className="box-body">
                            <div className="row">
                                {this.form()}
                            </div>
                          </div>
                                
                          </div>
                      </div>
                  </div>

                  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h3 class="modal-title" id="exampleModalLabel">Masukan nomor Whatsapp untuk keperluan wawancara</h3>
                          <h5 class="modal-title" id="exampleModalLabel">No Jurusan {this.state.id_selected}</h5>
                        </div>
                        <div class="modal-body">
                          <CurrencyFormat 
                              input="number"
                              name="whatsapp" 
                              format="+62############"
                              onChange={this.onChange} 
                              value={this.state.whatsapp}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="button" class="btn btn-primary" onClick={this.handleOnline()} >Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Home;