import React, { Component } from 'react';
import { Breadcrumb, Table, Button } from 'react-bootstrap';
import ReactExport from "react-export-excel";
import CurrencyFormat from 'react-currency-format';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data_student: '',
      is_show: false,
      data: '',
      totalUpp: 0,
      totalSpp : 0,
      rataUpp:0,
      rataSpp:0
    }
  }

  handleClick = param => e => {
    e.preventDefault();
    localStorage.setItem('student_id', param)
    window.open("/detailstudent", '_blank');

  }

  componentDidMount() {


    console.log((localStorage.getItem('data_report')))
    let data_report = JSON.parse(localStorage.getItem('data_report'));


    let data = []
    let total = 0;
    let total2 = 0;
    let rata = 0;
    let rata2 = 0;


   

    data_report.map((item, idx) => {
      let items = Object.create({});


      items.fullname = item.fullname;
      items.pendaftaran = item.year + '-' + item.registration_type_id + '-' + item.registration_number;
      items.hp = item.hp;
      items.status = item.interview_status ?
        item.interview_status.name : '';
      items.pewawancara = item.interview_by_user ?
        item.interview_by_user.user_id : '';
      items.spp = item.interview_by_user&&item.interview_status_id!=3 ?
        'Rp' + (item.data_bayar[1].nominal + item.data_bayar[2].nominal + item.data_bayar[3].nominal + item.data_bayar[4].nominal).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        : '';
      let nominal2 =0;
      if (item.interview_by_user&&item.interview_status_id!=3) {
        let potongan2 = item.data_bayar[0].nominal * item.data_bayar[0].potongan / 100
        nominal2 = item.data_bayar[0].nominal - potongan2;
        this.setState({ hasil: nominal2 })

        items.upp = 'Rp' + nominal2.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }  
      else { items.upp = '' }


      
      if (item.interview_by_user&&item.interview_status_id!=3 &&  item.data_bayar[0].nominal) {
        total += nominal2;
      }
      else { items.totalupp = '' }


      if (item.interview_by_user&&item.interview_status_id!=3 && item.data_bayar[1].nominal) {
          total2 += item.data_bayar[1].nominal + item.data_bayar[2].nominal + item.data_bayar[3].nominal + item.data_bayar[4].nominal;
        
      }
      else { items.totalSpp = '' }


      if(item.interview_by_user&&item.interview_status_id!=3 && item.data_bayar[0].nominal) {
        rata = total/data_report.length;

        // console.log("data report: " +data_report.length)
      }
      else { items.rataUpp = '' }


      if(item.interview_by_user&&item.interview_status_id!=3 && item.data_bayar[1].nominal) {
        rata2 = total2/data_report.length;

        // console.log("data report: " +data_report.length)
      }
      else { items.rataSpp = '' }

      items.note = item.note_verification ?
                   item.note_verification : '';




      data.push(items);
    })
    


   
      let items = Object.create({});
      items.fullname = 'TOTAL'
      items.upp = 'Rp' + total.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      items.spp = 'Rp' + total2.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      data.push(items);

      let items2 = Object.create({});
      items2.fullname = 'Rata-Rata'
      items2.upp = 'Rp' + rata.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      items2.spp = 'Rp' + rata2.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      data.push(items2);
    




    console.log("check total :" + total2);
    console.log(data);

    this.setState({ report: data ,totalUpp: total, totalSpp: total2, rataUpp: rata, rataSpp: rata2}, () => { this.setState({ is_show: true }) })
    
  }

  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Report Wawancara</Breadcrumb.Item>
                  </Breadcrumb>
                  {this.state.is_show ?
                    <ExcelFile element={<Button block style={{ margin: 5, height: 50 }}>Download Data</Button>}>
                      <ExcelSheet data={this.state.report} name="Report Wawancara">
                        <ExcelColumn label="Nama Lengkap" value="fullname" />
                        <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran" />
                        <ExcelColumn label="Nomor Handphone" value="hp" />
                        <ExcelColumn label="Status" value="status" />
                        <ExcelColumn label="Pewawancara" value="pewawancara" />
                        <ExcelColumn label="UPP" value="upp" />
                        <ExcelColumn label="SPP" value="spp" />
                        <ExcelColumn label="Catatan Tentang Pendaftar" value="note" />
                       
                      </ExcelSheet>
                    </ExcelFile>
                    : ''}

                  <div className="box-body">
                    <div className="row">
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nama Lengkap (Nama dapat diklik)</th>
                            <th>Nomor Pendaftaran</th>
                            <th>Nomor Handphone</th>
                            <th>Status</th>
                            <th>Pewawancara</th>
                            <th>UPP</th>
                            <th>SPP</th>
                            
                          </tr>
                        </thead>
                        <tbody>


                          {data_report.map((item, idx) => {
                            let totalupp = 0;
                            let nominal2 = 0;
                            if (item.interview_by_user&&item.interview_status_id!=3) {
                              let potongan2 = item.data_bayar[0].nominal * item.data_bayar[0].potongan / 100
                              nominal2 = item.data_bayar[0].nominal - potongan2
                            }

                            else { nominal2 = 0 }

                            if (item.interview_by_user&&item.interview_status_id!=3&& item.data_bayar[0].nominal) {

                              totalupp += item.data_bayar[0].nominal;

                            }

                            else { totalupp = '' }

                            console.log(item)
                            counter++;
                            return (
                              <tr>
                                <td>{counter}</td>
                                <td style={{ color: 'blue' }} onClick={this.handleClick(item.id)}>{item.fullname}</td>
                                <td>{item.year + '-' + item.registration_type_id + '-' + item.registration_number}</td>
                                <td>{item.hp}</td>

                                <td>{item.interview_status ?
                                  item.interview_status.name : ''}</td>
                                <td>{item.interview_by_user ?
                                  item.interview_by_user.user_id : ''}</td>
                                <td>{item.interview_by_user ?
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={nominal2}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /> : ''}</td>
                                <td>{item.interview_by_user&&item.interview_status_id!=3 ?
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={item.data_bayar[1].nominal + item.data_bayar[2].nominal + item.data_bayar[3].nominal + item.data_bayar[4].nominal}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /> : ''}</td>
                               
                              </tr>
                            )
                          })}
                        </tbody>
                        <tr>
                                <td colspan="6">TOTAL </td>
                              
                                <td>
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.state.totalUpp}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />&nbsp;</td>
                                    
                               <td>
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.state.totalSpp}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /></td>
                                    <td>&nbsp;</td>
                              </tr>
                              

                              <tr>
                                <td colspan="6">RATA-RATA</td>
                               <td>
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.state.rataUpp.toFixed(0)}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /></td>
                                   
                                <td>
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.state.rataSpp.toFixed(0)}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /></td>
                                    <td>&nbsp;</td>
                                
                              </tr>
                      </Table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;